<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :serverSideSorting="true"
            @view="onView"
            @view-doc="onViewDoc"
            @download="onDownload"
            responsive="sm"
            hover
            stateModule="issuedDocument/list"
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { IssuedDocumentModel } from '@/modules/issued-document/issued-document-model';

const { fields } = IssuedDocumentModel;

export default {
    name: 'IssuedDocumentListTable',
    data() {
        return {
            presenter: IssuedDocumentModel.presenter,
            actions: [
                {
                    name: 'view',
                    icon: 'eye',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                    customIcon: false,
                    type: 'primary'
                },
                {
                    name: 'view-doc',
                    icon: 'file-contract',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW_ORIGINAL'),
                    customIcon: false,
                    type: 'primary'
                },
                {
                    name: 'download',
                    icon: 'file-download',
                    tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                    customIcon: false,
                    type: 'primary'
                }
            ],
        };
    },
    computed: {
        ...mapGetters({
            rows: 'issuedDocument/list/rows',
            fileDownloadAccessToken: 'issuedDocument/list/fileDownloadAccessToken',
        }),
        fields() {
            return [
                fields.issuer_user_full_name.extendField({ sortable: true}),
                fields.document_series.extendField({ sortable: true}),
                fields.issued_date.extendField({ bold: true, sortable: true }),
                fields.document_name_text.extendField({ bold: true, sortable: true }),
                fields.beneficiary_full_names,
            ];
        }
    },
    methods: {
        ...mapActions({
            downloadFile: 'shared/downloadFile',
        }),
        onView(data) {
            if (!data?.item?.id) return;
            // Open in new tab
            let routeData = this.$router.resolve({
                name: 'applicationView',
                params: {
                    id: data.item.recipient_id,
                },
            });
            window.open(routeData.href, '_blank');
        },
        onViewDoc(doc) {
            window.open(`${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileDownloadAccessToken}`, '_blank');
        },
        onDownload(doc) {
            const url = `${window.VUE_APP_API_URL}/files/${doc.item.file_id}`;
            this.downloadFile({ url, fileName: `${doc.item.document_name}.pdf`, token:  this.fileDownloadAccessToken});
        }
    },
};
</script>
